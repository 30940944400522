import React, { useRef, useEffect, useState } from "react";
import styles from "./ItemType.module.css";

export default function ItemType({
  onClick,
  onCreate,
  blank,
  name,
  imageUrl,
  selected,
  rectangular,
}) {
  const inputRef = useRef(null);
  const [namee, setName] = useState(name);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(imageUrl);

  // Effect to update local state when name prop changes
  useEffect(() => {
    setName(name);
  }, [name]);

  useEffect(() => {
    if (blank && inputRef.current) {
      inputRef.current.focus();
    }
  }, [blank]);

  useEffect(() => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onClick(reader.result, selectedImage);
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setPreviewUrl(imageUrl);
    }
  }, [selectedImage, imageUrl]);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
    console.log(e.target.files[0]);
    e.target.value = null; // This clears the input
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCreate = async () => {
    if (!selectedImage) {
      console.error("No image selected");
      return;
    }

    onCreate(namee, selectedImage);
  };

  return (
    <div
      className={
        styles[
          namee
            ? "item-type"
            : rectangular
            ? "item-type-rectangular"
            : "item-type-nomargin"
        ]
      }
      style={{ zIndex: blank ? 301 : "inherit" }}
    >
      <div
        onClick={
          rectangular ? (blank ? null : () => onClick(imageUrl)) : onClick
        }
        className={styles["item-type-rect"]}
        style={{
          top: selected ? "-10px" : "initial",
        }}
      >
        <img
          src={previewUrl}
          alt={namee}
          className={styles["item-type-image"]}
        />
        {blank && rectangular && (
          <div className={styles["item-type-image-container"]}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              className={styles["item-type-image-input"]}
              onChange={handleImageChange}
              id="image-input"
            />
          </div>
        )}
      </div>
      {!rectangular && !blank && (
        <input
          ref={inputRef}
          className={`${styles["item-type-name"]} ${styles.noborder}`}
          value={namee}
          onChange={handleNameChange}
          disabled={true}
          style={{
            top: selected ? "-5px" : "initial",
            borderBottom: selected ? "1px solid #000" : "none",
          }}
        />
      )}
    </div>
  );
}
