import React from 'react';
import styles from './Join.module.css'; // Import the module.css file
import { useSearchParams } from "react-router-dom";

const LinktreePage = ({ setModal }) => {
  const [searchParams] = useSearchParams();
  
  const username = searchParams.get("username") || "";
  return (
    <div className={styles.linktreePage}>

      <div className={styles.dashboardContainer}>
        {/* Main Heading */}
        <div className={styles.mainHeading}>
      Lupa password
        </div>

        {/* Sub Heading */}
        <div className={styles.subHeading}>
      Masukkan email atau nomor whatsapp, kami akan memberimu kode akses
        </div>

        {/* Form Section */}
        <form className={styles.linktreeForm}>
          <label htmlFor="username" className={styles.usernameLabel}>--------- username atau email -------------</label>
          <input
            id="username"
            placeholder=""
            maxLength="30"
            className={styles.usernameInput}
            value={username}
          />
          <button type="submit" className={styles.claimButton}>
            <span>➜</span>
          </button>
        </form>

        {/* Footer Links */}
        <div className={styles.footer}>
          <div className={styles.footerLinks}>
            <a
              href="https://linktr.ee/discover/trending"
              target="_blank"
              rel="noreferrer"
              className={styles.footerLink}
            >
              Pelajari lebih lanjut
            </a>
            <a
              href="https://linktr.ee"
              target="_blank"
              rel="noreferrer"
              className={styles.footerLink}
            >
              Tentang kedaimaster.com
            </a>
          </div>
          <div className={styles.footerImage}>
            <img
              src="./laporan.png"
              alt="Linktree visual"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinktreePage;
