import React from 'react';
import './GuidePage.css';
import API_BASE_URL from '../config.js';
const GuidePage = ({ guideType }) => {
  const renderGuideContent = () => {
    switch (guideType) {
      case 'create_item':
        return (
          <div>
            <h2>Setup Guide</h2>
            <p>1. Turn on edit mode and create item type</p>
            <video
              src={`${API_BASE_URL}/uploads/create_item_guide_1.mkv`}
              autoPlay
              muted
              loop
              className="guide-video"
            />
          </div>
        );
      case 'troubleshooting':
        return (
          <div>
            <h2>Troubleshooting Guide</h2>
            <p>Follow these steps to troubleshoot common issues...</p>
            {/* Add more troubleshooting details here */}
          </div>
        );
      case 'features':
        return (
          <div>
            <h2>Features Guide</h2>
            <p>Learn about the different features available...</p>
            {/* Add more feature details here */}
          </div>
        );
      default:
        return (
          <div>
            <h2>Welcome to the Guide</h2>
            <p>Please select a guide type to get started.</p>
          </div>
        );
    }
  };

  return (
    <div className="guide-page">
      {renderGuideContent()}
    </div>
  );
};

export default GuidePage;
