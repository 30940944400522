import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styles from './LinktreePage.module.css';
import { loginUser, getAnalytics, createCafeOwner } from "../helpers/userHelpers";
import { getOwnedCafes, createCafe, updateCafe } from "../helpers/cafeHelpers";
import { getMyTransactions } from "../helpers/transactionHelpers";
import { unsubscribeUser } from "../helpers/subscribeHelpers.js";
import { getLocalStorage, removeLocalStorage } from "../helpers/localStorageHelpers";
import { ThreeDots } from "react-loader-spinner";
import Header from '../components/Header';
import CircularDiagram from "./CircularDiagram";
import API_BASE_URL from '../config';
import BarChart from '../components/BarChart';

const LinktreePage = ({ user, setModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [lastModal, setLastModal] = useState(false);

  const [inputtingPassword, setInputtingPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [newItem, setNewItem] = useState({ name: "", type: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedSubItemId, setSelectedSubItemId] = useState(0);


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const modalParam = urlParams.get('modal');
    if (lastModal && !modalParam) {
      if (selectedItemId == -1) setSelectedItemId(0);
      else if (selectedSubItemId == -1) setSelectedSubItemId(0);
    }
    if (modalParam) {
      setLastModal(modalParam);
    }

  }, [location]);

  // Handle user transactions
  const handleMyTransactions = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await getMyTransactions();
      if (response) {
        setItems(response);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
      console.log(items);
    }
  };

  // Handle login
  const handleLogin = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await loginUser(username, password);
      if (response.success) {
        localStorage.setItem('auth', response.token);
        window.location.href = response.cafeId ? `/${response.cafeId}` : '/';
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle logout
  const handleLogout = () => {
    removeLocalStorage("auth");
    unsubscribeUser();
    navigate(0);
  };

  // Fetch data when the user changes
  useEffect(() => {
    if (user) {
      setLoading(true);
      switch (user.roleId) {
        case 0:
          getAnalytics().then(setItems).catch(console.error).finally(() => setLoading(false));
          break;
        case 1:
          getAnalytics().then(setItems).catch(console.error).finally(() => setLoading(false));
          break;
        case 3:
          handleMyTransactions();
          break;
        default:
          setLoading(false);
          break;
      }
    }

    console.log(items);
  }, [user]);

  // Handle create item (admin or cafe owner)
  const handleCreateItem = async () => {
    try {
      if (user.roleId < 1) {
        const newOwner = await createCafeOwner(newItem.email, newItem.username, newItem.password);
        setItems([...items, { userId: newOwner.userId, name: newOwner.username }]);
      } else {
        const newCafe = await createCafe(newItem.name);
        setItems([...items, { cafeId: newCafe.cafeId, name: newCafe.name }]);
      }
      setIsCreating(false);
      setNewItem({ name: "", type: "" });
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  const formatIncome = (amount) => {
    if (amount >= 1_000_000_000) {
      // Format for billions
      const billions = amount / 1_000_000_000;
      return billions.toFixed(0) + "b"; // No decimal places for billions
    } else if (amount >= 1_000_000) {
      // Format for millions
      const millions = amount / 1_000_000;
      return millions.toFixed(2).replace(/\.00$/, "") + "m"; // Two decimal places, remove trailing '.00'
    } else if (amount >= 1_000) {
      // Format for thousands
      const thousands = amount / 1_000;
      return thousands.toFixed(1).replace(/\.0$/, "") + "k"; // One decimal place, remove trailing '.0'
    } else {
      // Less than a thousand
      if (amount != null) return amount.toString();
    }
  };

  const colors = [
    // Complementary (for contrast with olive green)
    "#FF6347", // Tomato red (complementary to olive green)
    "#FF4500", // Orange red (complementary to olive green)

    // Analogous to olive green
    "#D0E14F", // Light green-yellow
    "#A9C96E", // Muted olive green (your bg color itself)
    "#A5B24F", // Earthy olive green


    // Triadic (balanced and vibrant palette)
    "#FF00FF", // Magenta (triadic color)
    "#1E90FF", // Dodger blue (triadic color)
    "#32CD32", // Lime green (triadic color)

    // Neutral tones
    "#FFDAB9", // Peach (light neutral tone)
    "#4B0082", // Indigo (dark neutral tone)
    "#8B4513", // Saddle brown (earthy neutral)
  ];

  const selectedItems = items.items?.find(item => (item.userId || item.cafeId) === selectedItemId);

  // If the selected tenant is found, extract the cafes
  const selectedSubItems = selectedItems?.subItems || [];

  // 1. Optionally combine all report items from cafes of the selected tenant
  let allSelectedSubItems = null;
  if (user.roleId == 1)
    allSelectedSubItems = selectedSubItems?.flatMap(cafe => cafe.report?.items || selectedItems.report.items || []);

  // 2. Retrieve the specific cafe's report items if needed
  const filteredItems = selectedSubItems?.find(cafe => cafe.cafeId == selectedSubItemId) || { report: { items: [] } };

  // 3. Decide whether to use combined items or individual cafe items
  let segments = [];

  if (user.roleId == 1 || user.roleId == 0 && selectedItemId == 0)
    segments = (selectedItemId != 0 && selectedItemId != -1 && selectedSubItemId == 0 ? allSelectedSubItems : selectedItemId != 0 && selectedItemId != -1 ? filteredItems.report?.items || [] : items?.items || []).map((item, index) => ({
      percentage: item.percentage || (items.totalIncome / item.totalIncome || items.totalIncome / item.report.totalIncome) * 100,
      value: item.username || item.name,
      color: (colors && colors[index]) || "#cccccc", // Safe check for colors array
    })) || []; // Ensure segments is an empty array if no items are availabled

  // Function to combine items of all cafes for the selected tenant
  console.log(selectedItems)
  console.log(selectedItemId)
// Check if items and items.items are defined before proceeding
const allMaterials = (items?.items || []).flatMap(item => item.report?.materialsPurchased || []);

// Sort the merged array by date if it's not empty
const sortedMaterials = allMaterials.sort((a, b) => new Date(a.date) - new Date(b.date));



  return (
    <>
      {user && user.roleId < 2 ? (
        <>
          <div className={styles.nonCenteredLinktreePage}>

            <div className={styles.dashboard}>
              <div className={styles.header}>

                <Header
                  HeaderText={"selamat sore " + user.username}
                  isEdit={() => setIsModalOpen(true)}
                  isLogout={handleLogout}
                  user={user}
                  showProfile={true}
                  setModal={setModal}
                  HeaderMargin='0px'
                />
              </div>
              <div className={styles.headerCardWrapper}>
                <div className={styles.headerCard}>
                  {user.roleId == 0 ? (
                    selectedItemId == 0 || selectedItemId == -1 ? (
                      <div className={styles.cardBody}>
                        <div className={styles.cardItem}>
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                            <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                              <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                              <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                              <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                            </g>
                          </svg>
                          <p>Total pemasukan</p>
                          <p>{items?.totalIncome}</p>
                        </div>
                        <div className={styles.cardItem}>
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                            <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                              <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                              <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                              <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                            </g>
                          </svg>
                          <p>Total keuntungan</p>
                          <p>{items?.totalIncome * 0.02}</p>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.cardBody}>
                        <div className={styles.cardItem} >

                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                            <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                              <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                              <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                              <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                            </g>
                          </svg>
                          <p>{selectedItems?.totalIncome}</p>
                          <p>pemasukann</p>
                        </div>
                        <div className={styles.cardItem}>
                          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                            <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                              <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                              <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                              <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                            </g>
                          </svg>
                          <p>{selectedItems?.totalIncome * 0.02}</p>
                          <p>tagihan</p>
                        </div>
                        <div className={styles.cardImg}>
                          <img
                            src={filteredItems?.welcomePageConfig ?
                              API_BASE_URL + "/" + (JSON.parse(filteredItems.welcomePageConfig)?.image || '') : 'https://png.pngtree.com/png-vector/20221125/ourmid/pngtree-no-image-available-icon-flatvector-illustration-pic-design-profile-vector-png-image_40966566.jpg'
                            }
                          />
                          <p>{filteredItems.name}</p>
                        </div>
                      </div>
                    )
                  ) : (
                    selectedItemId == 0 || selectedItemId == -1 ?
                      (
                        <div className={styles.cardBody}>
                          <div className={styles.cardItem}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                              <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                                <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                                <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                                <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                              </g>
                            </svg>
                            <p>Total pemasukan</p>
                            <p>{formatIncome(items?.totalIncome)}</p>
                          </div>
                          <div className={styles.cardItem}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                              <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                                <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                                <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                                <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                              </g>
                            </svg>
                            <p>Total pengeluaran</p>
                            <p>{formatIncome(items?.totalOutcome)}</p>
                          </div>
                          <div className={styles.cardItem}>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                              <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                                <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                                <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                                <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                              </g>
                            </svg>
                            <p>Total tanggungan</p>
                            <p>{formatIncome(items?.totalIncome * 0.02)}</p>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.cardBody}>
                          <div className={styles.cardItem}>

                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                              <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                                <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                                <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                                <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                              </g>
                            </svg>
                            <p>{formatIncome(selectedItems?.report.totalIncome)}</p>
                            <p>pemasukan</p>
                          </div>
                          <div className={styles.cardItem} >
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="44.000000pt" height="44.000000pt" viewBox="0 0 224.000000 246.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <rect x="5" y="5" width="216" height="236" rx="200" ry="500" fill="rgb(255,255,255)" />
                              <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M1001 2449 c-459 -53 -835 -377 -957 -824 -25 -92 -28 -118 -32 -331
                                -4 -251 4 -328 54 -485 119 -376 418 -666 782 -760 131 -33 259 -44 370 -31
                                273 32 484 133 672 322 173 172 277 375 325 635 14 71 16 133 13 319 -4 213
                                -7 239 -32 331 -124 453 -498 771 -971 825 -101 11 -119 11 -224 -1z m326
                                -115 c254 -52 490 -212 637 -432 59 -89 98 -174 133 -287 25 -84 27 -103 27
                                -270 1 -158 -2 -189 -22 -259 -108 -376 -367 -628 -752 -733 -50 -13 -103 -17
                                -230 -17 -127 0 -180 4 -230 17 -385 105 -644 357 -752 733 -20 70 -23 101
                                -22 259 0 167 2 186 27 270 70 228 191 404 372 538 156 116 314 176 525 201
                                56 6 209 -4 287 -20z m-854 -1898 c56 -39 61 -49 48 -94 -6 -22 -9 -21 -68 33
                                -61 55 -169 189 -198 246 -10 18 17 -4 72 -59 49 -48 114 -105 146 -126z
                                m1512 185 c-44 -87 -245 -311 -278 -311 -4 0 -7 18 -5 40 2 38 7 44 72 92 39
                                28 105 86 146 129 41 43 76 78 78 78 1 1 -4 -12 -13 -28z m-1269 -315 c83 -32
                                182 -56 299 -74 l50 -7 0 -55 0 -55 -50 4 c-84 6 -227 47 -313 90 l-82 41 0
                                45 c0 25 2 45 4 45 2 0 44 -15 92 -34z m892 -18 c-3 -42 -4 -44 -76 -81 -74
                                -39 -213 -79 -309 -90 l-53 -6 0 58 0 59 65 7 c82 8 237 47 295 73 71 32 82
                                30 78 -20z"/>
                                <path d="M995 2216 c-5 -2 -43 -11 -82 -21 -211 -48 -397 -178 -523 -365 -195
                                -288 -189 -700 14 -984 235 -329 663 -457 1038 -310 195 76 380 249 468 436
                                129 275 105 614 -60 858 -123 182 -297 306 -515 366 -55 15 -313 30 -340 20z
                                m311 -116 c226 -58 403 -200 507 -405 118 -235 108 -512 -25 -740 -53 -90
                                -183 -220 -273 -273 -244 -144 -546 -144 -790 0 -90 53 -220 183 -273 273
                                -133 228 -143 505 -25 740 103 204 280 346 503 404 105 28 270 28 376 1z"/>
                                <path d="M630 1401 l0 -331 80 0 80 0 0 125 0 125 28 0 c25 -1 31 -10 83 -123
                                l56 -122 91 -3 c51 -1 92 0 92 3 0 3 -30 64 -66 136 l-66 132 41 41 c22 22 45
                                54 50 69 25 66 5 155 -47 210 -53 56 -73 61 -254 65 l-168 4 0 -331z m297 167
                                c29 -27 29 -65 1 -95 -18 -19 -32 -23 -80 -23 l-58 0 0 70 0 70 57 0 c44 0 62
                                -5 80 -22z"/>
                                <path d="M1220 1240 l0 -330 75 0 75 0 0 96 c0 88 2 95 18 89 74 -32 132 -30
                                190 5 52 32 65 75 65 221 0 154 -13 192 -83 227 -54 27 -101 28 -157 2 -43
                                -19 -43 -19 -43 0 0 18 -7 20 -70 20 l-70 0 0 -330z m252 183 c25 -23 27 -177
                                1 -205 -24 -27 -69 -23 -89 7 -20 31 -17 172 5 198 17 22 59 22 83 0z"/>
                              </g>
                            </svg>
                            <p>{formatIncome(selectedItems?.report.currentOutcome)}</p>
                            <p>pengeluaran</p>
                          </div>
                          <div className={styles.cardImg}>
                            <img
                              src={selectedItems?.welcomePageConfig ?
                                API_BASE_URL + "/" + (JSON.parse(selectedItems.welcomePageConfig)?.image || '') : 'https://png.pngtree.com/png-vector/20221125/ourmid/pngtree-no-image-available-icon-flatvector-illustration-pic-design-profile-vector-png-image_40966566.jpg'
                              }
                            />
                            <p>{selectedItems?.name}</p>
                          </div>
                        </div>
                      )
                  )}

                </div>
              </div>

            </div>
            <div className={styles.dashboardBody}>
              <button className={styles.goCafeButton} style={{ visibility: (selectedItems?.cafeId || selectedSubItems.find(cafe => cafe.cafeId == selectedSubItemId)?.cafeId) == null ? 'hidden' : 'visible' }} onClick={() => window.location.href = window.location.origin + '/' + (selectedItems?.cafeId || selectedSubItems.find(cafe => cafe.cafeId == selectedSubItemId)?.cafeId)}>Kunjungi kedai</button>

              <h3>terlakuu</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: '5px'
                }}
              >
                <div style={{ flex: 1 }}>
                  <CircularDiagram segments={segments} />
                </div>
                <div style={{ flex: "1 1 20%" }}>
                  {segments && segments.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                          fontSize: "1.2em",
                          color: colors[index],
                        }}
                      >
                        ★
                      </div>
                      <h5 style={{ margin: 0, textAlign: "left" }}>{item.percentage == 'Infinity' || isNaN(item.percentage) ? 0 : item.percentage}% &nbsp; {item.value} </h5>
                    </div>
                  ))}
                  {segments.length < 1 &&
                    <>

                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            style={{
                              width: '100%',
                              margin: 0,
                              textAlign: "left",
                              backgroundColor: 'gray',
                              color: 'gray',
                              borderRadius: '20px',
                              marginBottom: '2px',
                            }}
                          >
                            -
                          </h5>
                        </div>
                      ))}

                    </>
                  }
                </div>

              </div>
              <h3>penambahan stok</h3>
              <BarChart Data={selectedItems?.report?.materialsPurchased || sortedMaterials} />
              <div style={{ height: '24vh' }}></div>
            </div>

          </div>
          <div className={styles.cafeListWrapper}>
            <div className={styles.cafeListHeader}>
              Semua {user.roleId < 1 ? 'penyewa' : 'kedai yang dikau miliki'}
            </div>
            <div className={styles.cafeList}>
            </div>

            {user.roleId < 1 &&
              <div className={styles.rectangle}
                style={{ backgroundColor: selectedItemId == -1 ? 'rgb(69, 69, 69)' : 'rgb(114, 114, 114)' }}
                onClick={() => {
                  setSelectedItemId(selectedItemId === -1 ? 0 : -1);
                  setModal('create_tenant');
                }}
              >
                Tambah penyewa
              </div>
            }
            {
              items?.items?.length > 0 ? (
                items.items.map((item) => {
                  const isTenantSelected = selectedItemId === (item.userId || item.cafeId);
                  const tenantBackgroundColor = isTenantSelected && !selectedSubItemId ? 'rgb(69, 69, 69)' : 'rgb(114, 114, 114)';
                  const hasSubItems = item?.subItems?.length > 0;

                  return (
                    <div key={item.userId || item.cafeId}>
                      <div
                        onClick={() => {
                          setSelectedItemId(isTenantSelected && !selectedSubItemId ? 0 : (item.userId || item.cafeId));
                          setSelectedSubItemId(0); // Reset subitem selection when changing tenant
                        }}
                        style={{ backgroundColor: tenantBackgroundColor }}
                        className={isTenantSelected ? styles.rectangleNLine : styles.rectangle}
                      >
                        <h3>{item.username || item.name} &nbsp;</h3>
                        <h3>
                          Rp{formatIncome(item.totalIncome || item.report?.totalIncome) || 0}
                        </h3>
                      </div>

                      {/* Only show cafes if the tenant is selected */}
                      {selectedItemId === (item.userId || item.cafeId) && hasSubItems && item.subItems.map((subItem) => {
                        const isCafeSelected = selectedSubItemId == (subItem.cafeId || subItem.userId);
                        const cafeBackgroundColor = isCafeSelected ? 'rgb(69, 69, 69)' : 'rgb(114, 114, 114)';

                        return (
                          <div
                            key={subItem.cafeId || subItem.userId}
                            className={styles.subRectangle}
                            onClick={() => {
                              setSelectedSubItemId(isCafeSelected ? 0 : (subItem.cafeId || subItem.userId)); // Toggle subitem selection
                              setSelectedItemId(item.userId || item.cafeId); // Ensure tenant stays selected
                            }}
                            style={{ backgroundColor: cafeBackgroundColor }}
                          >
                            {subItem.name || subItem.username}
                            {/* &nbsp;pendapatan {formatIncome(subItem.report?.totalIncome || 0)} */}
                          </div>
                        );
                      })}
                      {selectedItemId == item.cafeId &&
                        <div
                          className={styles.subRectangle}
                          onClick={() => { setSelectedSubItemId(selectedSubItemId == -1 ? 0 : -1); setModal('create_clerk') }}
                          style={{ backgroundColor: selectedItemId == item.cafeId && selectedSubItemId == -1 ? 'rgb(69, 69, 69)' : 'rgb(114, 114, 114)' }}
                        >
                          tambah kasir
                        </div>
                      }
                    </div>
                  );
                })
              ) : (
                <div>No tenants available</div>
              )
            }
            {user.roleId > 0 &&
              <div className={styles.rectangle}
                style={{ backgroundColor: selectedItemId == -1 ? 'rgb(69, 69, 69)' : 'rgb(114, 114, 114)' }}
                onClick={() => { setSelectedItemId(selectedItemId == -1 ? 0 : -1); setModal('create_kedai') }}
              >
                Tambah kedai
              </div>
            }
            <div style={{ height: '2px' }}></div>
          </div></>
      ) : (
        <div className={styles.centeredLinktreePage}>
          <div className={styles.dashboardLine}></div>
          <div className={styles.dashboardContainer}>
            <div className={styles.mainHeading}>
              COBA KEDAIMASTER
              <div className={styles.swipeContainer}>
                <div className={styles.swipeContent}>
                  {['pemesanan langsung dari meja', 'pengelolaan pesanan dan keuangan', 'tentukan suasana musik', 'pengelolaan stok dan manajemen', 'jangan pernah ragukan pelanggan'].map((item, index) => (
                    <div key={index} className={styles.swipeItem}>{item}</div>
                  ))}
                </div>
              </div>
              diskon 0%
            </div>

            <div className={styles.subHeading}>
              Solusi berbasis web untuk memudahkan pengelolaan kedai, dengan fitur yang mempermudah pemilik, kasir, dan tamu berinteraksi.
            </div>

            {getLocalStorage('auth') == null && (
              <div className={styles.LoginForm}>
                <div className={`${styles.FormUsername} ${inputtingPassword ? styles.animateForm : styles.reverseForm}`}>
                  <label htmlFor="username" className={styles.usernameLabel}>---- masuk -------------------------------</label>
                  <input
                    id="username"
                    placeholder="username"
                    maxLength="30"
                    className={!error ? styles.usernameInput : styles.usernameInputError}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <button onClick={() => setInputtingPassword(true)} className={styles.claimButton}>
                    <span>➜</span>
                  </button>
                </div>

                <div className={`${styles.FormPassword} ${inputtingPassword ? styles.animateForm : styles.reverseForm}`}>
                  <span>
                    <label onClick={() => setInputtingPassword(false)} htmlFor="password" className={styles.usernameLabel}> &lt;--- &lt;-- kembali </label>
                    <label htmlFor="password" className={styles.usernameLabel}> &nbsp; ----- &nbsp; </label>
                    <label onClick={() => setModal('reset-password', { username: username })} className={styles.usernameLabel}>
                      lupa password? -
                    </label>
                  </span>
                  <input
                    id="password"
                    placeholder="password"
                    type="password"
                    maxLength="30"
                    className={!error ? styles.usernameInput : styles.usernameInputError}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    onClick={handleLogin}
                    className={`${styles.claimButton} ${loading ? styles.loading : ''}`}
                    disabled={loading}
                  >
                    <span>{loading ? 'Loading...' : 'Masuk'}</span>
                  </button>
                </div>
              </div>
            )}

            <div className={styles.footer}>
              <div className={styles.footerLinks}>
                <a href="https://linktr.ee/discover/trending" target="_blank" rel="noreferrer" className={styles.footerLink}>Pelajari lebih lanjut</a>
                <a href="https://linktr.ee" target="_blank" rel="noreferrer" className={styles.footerLink}>Tentang kedaimaster.com</a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.signupButton}
                  onClick={() => setModal('join')}
                >
                  Daftarkan kedaimu
                </a>
              </div>
              <div className={styles.footerImage}>
                <img
                  style={{ height: '226px', width: '150px', objectFit: 'cover' }}
                  src="/kedai.png"
                  alt="Linktree visual"
                  onError={(e) => e.target.src = '/fallback-image.png'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinktreePage;
