import React, { useEffect, useState } from "react";
import {
  getReports,
} from "../helpers/transactionHelpers.js";
import CircularDiagram from "./CircularDiagram";
import styles from "./Transactions.module.css";
import "./Switch.css";

import MultiSwitch from "react-multi-switch-toggle";

const RoundedRectangle = ({
  onClick,
  bgColor,
  title,
  value,
  percentage,
  invert,
  fontSize = "15px",
  loading = false,
  children, // Assuming this is a React component or JSX
  isChildren,
}) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: !children && !isChildren && "calc(100% / 2 - 10px)",
    height: "auto",
    borderRadius: "15px",
    padding: "20px",
    margin: "5px",
    textAlign: "left",
    fontFamily: "Arial, sans-serif",
    boxSizing: "border-box",
    backgroundColor: loading ? "rgb(127 127 127)" : bgColor,
  };

  const titleStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
    width: "100%",
    backgroundColor: loading
      ? "rgb(85 85 85)"
      : !isChildren && !children && "inherit",
    color: loading ? "transparent" : "inherit",
  };

  const valueAndPercentageContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  };

  const valueStyle = {
    fontSize: loading ? "15px" : fontSize,
    fontWeight: "bold",
    flex: "1",
    textAlign: "left",
    color: loading ? "transparent" : "inherit",
    backgroundColor: loading ? "rgb(85 85 85)" : "transparent",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const percentageStyle = {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: loading ? "black" : percentage >= 0 ? (invert ? "red" : "#2fd45e") : (invert ? "#2fd45e" : "red"),
  };

  const arrowStyle = {
    marginLeft: "5px",
  };

  return (
    <div style={containerStyle} onClick={onClick}>
      <div style={titleStyle}>{title}</div>
      {!children && (
        <div style={valueAndPercentageContainerStyle}>
          <div style={valueStyle}>{loading ? "Loading..." : value}</div>
          <div style={percentageStyle}>
            {loading ? "" : percentage}
            {percentage !== undefined && !loading && "%"}
            {percentage !== undefined && !loading && (
              <span style={arrowStyle}>
                {percentage > 0 ? "↗" : percentage === 0 ? "-" : "↘"}
              </span>
            )}
          </div>
        </div>
      )}
      {children && <div>{children}</div>} {/* Properly render children */}
    </div>
  );
};

const App = ({ cafeId, 
  handleClose }) => {
  const [analytics, setAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("daily");

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      // Fetch the analytics data with the selected filter
      const analyticsData = await getReports(cafeId, filter);
      console.log(analyticsData);
      if (analyticsData) setAnalytics(analyticsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter); // Fetch data when filter changes
  }, [filter]);

  const filteredItems = analytics.items || [];

  const colors = [
    "#FF0000", // Red
    "#FF6F00", // Dark Orange
    "#FFD700", // Gold
    "#32CD32", // Lime Green
    "#00CED1", // Dark Turquoise
    "#1E90FF", // Dodger Blue
    "#8A2BE2", // BlueViolet
    "#FF00FF", // Magenta
    "#FF1493", // Deep Pink
    "#FF4500", // OrangeRed
    "#FFDAB9", // Peach Puff
    "#4B0082", // Indigo
    "#00FF7F", // Spring Green
    "#C71585", // Medium Violet Red
    "#F0E68C", // Khaki
    "#FF6347", // Tomato
    "#006400", // Dark Green
    "#8B4513", // SaddleBrown
    "#00BFFF", // Deep Sky Blue
    "#FF69B4", // Hot Pink
  ];

  const segments = filteredItems.map((item, index) => ({
    percentage: item.percentage,
    color: colors[index] || "#cccccc",
  }));

  const formatIncome = (amount) => {
    if (amount >= 1_000_000_000) {
      // Format for billions
      const billions = amount / 1_000_000_000;
      return billions.toFixed(0) + "b"; // No decimal places for billions
    } else if (amount >= 1_000_000) {
      // Format for millions
      const millions = amount / 1_000_000;
      return millions.toFixed(2).replace(/\.00$/, "") + "m"; // Two decimal places, remove trailing '.00'
    } else if (amount >= 1_000) {
      // Format for thousands
      const thousands = amount / 1_000;
      return thousands.toFixed(1).replace(/\.0$/, "") + "k"; // One decimal place, remove trailing '.0'
    } else {
      // Less than a thousand
      if (amount != null) return amount.toString();
    }
  };

  function roundToInteger(num) {
    return Math.round(num);
  }

  function onToggle(selectedItem) {
    const filterMap = ["daily", "weekly", "monthly", "yearly"];
    setFilter(filterMap[selectedItem]);
  }

  const filterTexts = ["1", "7", "30", "365"];
  const comparisonText =
    filterTexts[["daily", "weekly", "monthly", "yearly"].indexOf(filter)];
  // const formatDate = (isoDateString) => {
  //   const date = new Date(isoDateString);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //     hour: "numeric",
  //   });
  // };

  return (
    <div style={{
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      top: 0,
      right: 0,
      backgroundColor: 'rgb(207, 207, 207)',
      overflowY: 'auto'}}
      >
      <h2 className={styles["Transactions-title"]}>Laporan</h2>
      <div style={{ textAlign: "center", 
    marginTop: '30px' }}>
        <MultiSwitch
          texts={["kemarin", "minggu ini", "bulan ini", "tahun ini"]}
          selectedSwitch={["daily", "weekly", "monthly", "yearly"].indexOf(
            filter
          )}
          borderWidth={0}
          bgColor={"rgb(229, 236, 246)"}
          onToggleCallback={onToggle}
          fontColor={"rgba(88, 55, 50, 1)"}
          selectedFontColor={"#1e311b"}
          selectedSwitchColor={"rgb(227, 245, 255)"}
          eachSwitchWidth={70}
          height={"25px"}
          fontSize={"12px"}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <RoundedRectangle
            bgColor="#E3F5FF"
            title="Pendapatan"
            fontSize="12px"
            value={!loading && "Rp" + formatIncome(analytics?.totalIncome)}
            percentage={roundToInteger(analytics.growthIncome)}
            invert={false}
            loading={loading}
          />

<RoundedRectangle
            bgColor="#E5ECF6"
            title="Pengeluaran"
            fontSize="12px"
            value={!loading && "Rp" + formatIncome(analytics?.currentOutcome)}
            percentage={roundToInteger(analytics.growthOutcome)}
            invert={true}
            loading={loading}
          />
          <RoundedRectangle
            bgColor="#E5ECF6"
            title="Transaksi"
            value={analytics.totalTransactions}
            percentage={roundToInteger(analytics.growthTransactions)}
            invert={false}
            loading={loading}
          />
          {analytics?.items &&
            analytics?.items.length > 0 && (
              <RoundedRectangle
                bgColor="#E3F5FF"
                title={"Item favorit"}
                value={analytics?.items[0]?.name}
                loading={loading}
              />
            )}
            {(!analytics?.items ||
              analytics?.items.length === 0) && (
              <RoundedRectangle
                bgColor="#E5ECF6"
                title={"Item favorit"}
                value={"-"}
                loading={loading}
              />
            )}
          <div
            style={{ display: "flex", alignItems: "center", margin: "10px" }}
          >
            <div style={{ marginRight: "5px", fontSize: "1.2em" }}>ⓘ</div>
            <h6 style={{ margin: 0, textAlign: "left" }}>
            Persentase pertumbuhan dihitung dengan membandingkan periode{" "}
              {comparisonText} terakhir dengan {comparisonText} hari sebelumnya.
            </h6>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <CircularDiagram segments={segments} />
          </div>
          <div style={{ flex: 1, marginLeft: "20px" }}>
            {filteredItems.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    marginRight: "5px",
                    fontSize: "1.2em",
                    color: colors[index],
                  }}
                >
                  ★
                </div>
                <h5 style={{ margin: 0, textAlign: "left" }}>{item.name}</h5>
              </div>
            ))}
          </div>
        </div>

      </div>
      <div class="ItemLister_PaymentOption__YZlDL"><div style={{marginTop:'20px'}} onClick={handleClose} class="ItemLister_Pay2Button__+MIxX">Kembali</div></div>
    </div>
  );
};

export default App;
