import React from "react";

const NotificationBlocked = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Notifikasi Terblokir</h2>
      <p style={styles.message}>
        Sepertinya notifikasi untuk situs ini tidak aktif. Aktifkan notifikasi supaya kamu tetap dapat info pesanan, meski sedang buka aplikasi lain.
      </p>
      <p style={styles.message}>
        Berikut cara mengaktifkannya:
      </p>
      <ol style={styles.instructions}>
        <li>
          Klik ikon{" "}
          <span style={styles.icon}>
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              style={{ verticalAlign: "middle", display: "inline-block" }}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  fill="#000000"
                  fillRule="evenodd"
                  d="M4,8 C5.240909,8 6.30584855,8.75341602 6.76233298,9.8277624 L6.82929,10 L14,10 C14.5523,10 15,10.4477 15,11 C15,11.51285 14.613973,11.9355092 14.1166239,11.9932725 L14,12 L6.82929,12 C6.41746,13.1652 5.30622,14 4,14 C2.34315,14 1,12.6569 1,11 C1,9.34315 2.34315,8 4,8 Z M4,10 C3.44772,10 3,10.4477 3,11 C3,11.5523 3.44772,12 4,12 C4.55228,12 5,11.5523 5,11 C5,10.4477 4.55228,10 4,10 Z M12,2 C13.6569,2 15,3.34315 15,5 C15,6.65685 13.6569,8 12,8 C10.75911,8 9.69415335,7.24658397 9.23766716,6.1722376 L9.17071,6 L2,6 C1.44772,6 1,5.55228 1,5 C1,4.48716857 1.38604429,4.06449347 1.88337975,4.0067278 L2,4 L9.17071,4 C9.58254,2.83481 10.6938,2 12,2 Z M12,4 C11.4477,4 11,4.44772 11,5 C11,5.55228 11.4477,6 12,6 C12.5523,6 13,5.55228 13,5 C13,4.44772 12.5523,4 12,4 Z"
                ></path>
              </g>
            </svg>
          </span>{" "}
          di pojok kiri atas
        </li>
        <li>
          Pilih <strong>Izin</strong> &gt; <strong>Notifikasi</strong> &gt; <strong>Izinkan Notifikasi</strong>.
        </li>
      </ol>
      <p style={styles.footer}>
        Setelah itu, kamu bisa terus dapet informasi pesanan meski gak lagi buka situs ini.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    maxWidth: "400px",
    margin: "20px auto",
    textAlign: "center",
  },
  header: {
    color: "#e74c3c",
  },
  message: {
    marginBottom: "15px",
  },
  instructions: {
    listStyleType: "decimal",
    paddingLeft: "20px",
    textAlign: "left",
  },
  footer: {
    marginTop: "20px",
    fontStyle: "italic",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "20px",
    height: "20px",
    marginBottom: '6px'
  },
};

export default NotificationBlocked;
