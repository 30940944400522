// notificationService.js
export const requestNotificationPermission = async () => {
    let permission = Notification.permission;
  
    // Check current permission
    if (permission === "default") {
      const result = await Notification.requestPermission();
      permission = result; // Update permission status
    }
  
    // Return permission status
    return permission;
  };
  